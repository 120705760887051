import React from 'react';
import '../../styles/layoutDays/LayoutDays.css';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Footer from '../layoutHome/Footer';
import Header from '../layoutHome/Header';
import MenuButton from './MenuButton';
import BoxDays from './BoxDays';

const LayoutDays = () => (
  <div className="body-days">
    <div className="header-days">  <Header title="StriveLabs" /> </div>
    <div className="menu-days"> <MenuButton /> </div>
    <div className="main-days">
      <Link to="/timesheets/today" style={{ color: 'black' }}>
        <BoxDays name="Today" day={moment().format('dddd')} date={moment().format('D/MM/YYYY')} />
      </Link>
      <Link to="/timesheets/yesterday" style={{ color: 'black' }}>
        <BoxDays name="Yesteday" day={moment().add(-1, 'days').format('dddd')} date={moment().add(-1, 'days').format('D/MM/YYYY')} />
      </Link>
      <Link to="/timesheets/twoDaysAgo" style={{ color: 'black' }}>
        <BoxDays name="Two days ago" day={moment().add(-2, 'days').format('dddd')} date={moment().add(-2, 'days').format('D/MM/YYYY')} />
      </Link>
      <Link to="/timesheets/threeDaysAgo" style={{ color: 'black' }}>
        <BoxDays name="Three days ago" day={moment().add(-3, 'days').format('dddd')} date={moment().add(-3, 'days').format('D/MM/YYYY')} />
      </Link>
    </div>
    <div className="footer-days"> <Footer />  </div>
  </div>
);
export default LayoutDays;
