import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Alert } from 'react-bootstrap';
import { getDatabaseTimesheets } from '../../callAPI';
import { useStateValue } from '../../state';
import actions from '../../constants/actions';
import CardDatabase from './CardDatabase';

const TimesheetsDatabase = () => {
  const [{
    databasetimesheets,
  }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [errorDatabase, setErrorDatabase] = useState(false);
  const [textError, setTextError] = useState('');

  useEffect(() => {
    const fetchCommits = async () => {
      if (databasetimesheets.length) return;
      setLoading(true);
      const database = await getDatabaseTimesheets(true);
      if (!database.error) {
        dispatch({ type: actions.ADD_DATABASE_TIMESHEETS, payload: database.payload });
        setErrorDatabase(false);
      } else {
        setErrorDatabase(true);
        setTextError(database.payload);
      }
      setLoading(false);
    };
    fetchCommits();
  }, [databasetimesheets.length, dispatch]);

  if (loading) {
    return (
      <Loader type="Rings" color="#31c6e8" height={150} width={100} />
    );
  }

  if (errorDatabase) {
    return (
      <Alert key="error" variant="danger">
        <Alert.Heading>Alert! Error download data from database</Alert.Heading>
        <p>
          {textError}
        </p>
        <hr />
      </Alert>
    );
  }

  return (
    databasetimesheets.map(({
      _id, title, blacklist, timesheetId,
    }) => (
      <CardDatabase
        _id={_id}
        title={title}
        blacklist={blacklist}
        timesheetId={timesheetId}
        key={_id}
      />
    ))
  );
};
export default TimesheetsDatabase;
