const actions = {
  ADD_GITLAB_DATA: 'ADD_GITLAB_DATA',
  ADD_GITHUB_DATA: 'ADD_GITHUB_DATA',
  ADD_TIMESHEETS: 'ADD_TIMESHEETS',
  ADD_DATABASE_TIMESHEETS: 'ADD_DATABASE_TIMESHEETS',
  DELETE_TIMESHEET: 'DELETE_TIMESHEET',
  DELETE_SHEET: 'DELETE_SHEET',
  DELETE_BLACKLIST_NAME: 'DELETE_BLACKLIST_NAME',
  DELETE_TIMESHEET_HIDE: 'DELETE_TIMESHEET_HIDE',
  ADD_PREVIOUSLY_HIDDEN_SHEET: 'ADD_PREVIOUSLY_HIDDEN_SHEET',
  ADD_DAİLY_MESSAGE: 'ADD_DAİLY_MESSAGE',
};

export default actions;
