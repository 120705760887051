import React, { useState } from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AlertModal from './modal/AlertModal';
import { addBlacklistField } from '../../callAPI';
import actions from '../../constants/actions';
import { useStateValue } from '../../state';
import '../../styles/layoutTimesheets/MainTimesheets/RowOfWorkers.css';
import WorkerInformation from './modal/WorkerInformation';

const isCompletedColor = (isCompleted) => (isCompleted ? 'isCompletedBlue' : 'isCompletedRed');

const checkGeneralTimesheetColor = ((isGeneralTimesheet, isFoundIntimesheetGeneral, isSameHours) => {
  if (!isGeneralTimesheet && !isFoundIntimesheetGeneral) {
    return 'nameSheetRed';
  }

  if (!isGeneralTimesheet && !isSameHours) {
    return 'nameSheetOrange';
  }

  return 'nameSheetBlack';
});


const RowOfWorkers = ({
  nameSheet, isCompleted, totalHours, idDatabaseTimesheet, showEditTimesheets, dataRelatedToGeneralTimesheet, description,
}) => {
  const {
    isGeneralTimesheet, isFoundIntimesheetGeneral, isSameHours, information,
  } = dataRelatedToGeneralTimesheet;
  const dispatch = useStateValue()[1];
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [textError, setTextError] = useState('');
  const [showInformation, setShowInformation] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  const showModal = () => setShow(true);
  const hideModal = () => setShow(false);

  const onSuccess = async (id, sheet) => {
    const response = await addBlacklistField(id, sheet);
    if (!response.error) {
      dispatch({ type: actions.DELETE_SHEET, payload: { sheet, id } });
      hideModal();
      setError(false);
      setTextError('');
    } else {
      setError(true);
      setTextError(response.payload);
    }
  };

  return (
    <>
      <div className="nameSheet-isCompleted-deleteButton">
        <div
          className={checkGeneralTimesheetColor(isGeneralTimesheet, isFoundIntimesheetGeneral, isSameHours)}
          onClick={() => setShowInformation(true)}
        >
          {nameSheet}
        </div>
        <div className={isCompletedColor(isCompleted)} onClick={() => setShowDescription(true)}>
          <div>{totalHours}</div>
        </div>
        <div className={showEditTimesheets ? 'deleteButton' : 'deleteButton-hide'}>
          <FontAwesomeIcon icon={faTrashAlt} size="xs" onClick={showModal} />
        </div>
      </div>
      <AlertModal
        show={show}
        onClose={hideModal}
        onSuccess={() => onSuccess(idDatabaseTimesheet, nameSheet)}
        head="Delete sheet?"
        error={error}
        textError={textError}
      />
      {showInformation
        && (
          <WorkerInformation
            onClose={() => setShowInformation(false)}
            show={showInformation}
            header={nameSheet}
            informationWorker={information}
            delay={3000}
          />
        )}
      {showDescription
        && (
          <WorkerInformation
            onClose={() => setShowDescription(false)}
            show={showDescription}
            header="Description"
            informationWorker={description}
            delay={8000}
          />
        )}
    </>
  );
};
export default RowOfWorkers;
