import {
  BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import React from 'react';
import { motion } from 'framer-motion';
import LayoutHome from './layoutHome/LayoutHome';
import LayoutDays from './layoutDays/LayoutDays';
import LayoutTimesheets from './layoutTimesheets/LayoutTimesheets';
import LayoutCalendar from './layoutCalendar/LayoutCalendar';
import LayoutGit from './layoutGit/LayoutGit';
import LayoutSlack from './layoutSlack/layoutSlack';

const Routing = () => {
  const displayHome = () => (
    <div>
      <LayoutHome />
    </div>
  );

  const displayDays = () => (
    <div>
      <LayoutDays />
    </div>
  );
  const displayTimeSheets = ({ match }) => (
    <div>
      <LayoutTimesheets dateOption={match.params.dateOpcion} />
    </div>
  );
  const displayCalendar = ({ match }) => (
    <div>
      <LayoutCalendar from={match.params.from} />
    </div>
  );

  const displayGit = ({ location }) => {
    const query = new URLSearchParams(location.search);
    return (
      <div>
        <LayoutGit date={query.get('date')} />
      </div>
    );
  };
  const displaySlack = ({ location }) => {
    const query = new URLSearchParams(location.search);
    return (
      <div>
        <LayoutSlack date={query.get('date')} />
      </div>
    );
  };

  const displayMember = ({ location }) => {
    const query = new URLSearchParams(location.search);
    return (
      <div>
        building
      </div>
    );
  };


  return (
    <Router>
      <Route render={({ location }) => (
        <motion.div
          initial={{ x: 300, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          exit={{ x: -300, opacity: 0 }}
          transition={{ duration: 0.5 }}
          key={location.pathname}
        >
          <Switch location={location}>
            <Route exact path="/" component={displayHome} />
            <Route path="/timesheets/:dateOpcion" component={displayTimeSheets} />
            <Route path="/days" component={displayDays} />
            <Route path="/calendar/:from" component={displayCalendar} />
            <Route path="/git" component={displayGit} />
            <Route path="/member" component={displayMember} />
            <Route path="/daily" component={displaySlack} />
          </Switch>
        </motion.div>
      )}
      />
    </Router>
  );
};

export default Routing;
