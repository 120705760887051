import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import '../../styles/git/RowGitWorkers.css';


const RowGitWorkers = ({
  isHidden, gitApi, userId, name, _id, counter, showEditGit,
}) => (
  <>
    <div className="nameProject-counter-deleteButton">

      <div className="projectName">
        <Link to={`/member?_id=${_id}&`} style={{ color: 'black' }}>
          {name}
        </Link>
      </div>

      <div className="counter">
        <div>{counter}</div>
      </div>
      <div className={showEditGit ? 'deleteButton' : 'deleteButton-hide'}>
        <FontAwesomeIcon icon={faTrashAlt} size="xs" />
      </div>
    </div>
  </>
);
export default RowGitWorkers;
