import React from 'react';
import '../../styles/layoutDays/BoxDays.css';
import '../../styles/layoutDays/MenuButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const MenuButton = () => (
  <div className="button-home">
    <Link to="/" style={{ color: 'black' }}>
      <FontAwesomeIcon className="button" icon={faHome} size="lg" />
    </Link>
  </div>
);
export default MenuButton;
