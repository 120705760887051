import React from 'react';
import '../../styles/layoutTimesheets/MenuButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEyeSlash, faHome, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


const MenuButtons = ({ switchShowState }) => (
  <div className="buttons">
    <div className="buttons-add-hide-edit">
      <div className="hide-button">
        <FontAwesomeIcon icon={faEyeSlash} size="lg" />
      </div>
      <div className="edit-button">
        <FontAwesomeIcon icon={faEdit} size="lg" onClick={switchShowState} />
      </div>
    </div>
    <div className="button-home">
      <Link to="/" style={{ color: 'black' }}>
        <FontAwesomeIcon className="home-button" icon={faHome} size="lg" />
      </Link>
    </div>
  </div>
);

export default MenuButtons;
