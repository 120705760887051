import React from 'react';
import '../../styles/layoutTimesheets/HeaderControl.css';
import git from '../../img/git.png';
import logoChange from '../../img/logo_change.png';

const HeaderControl = () => (
  <div className="header-control-container">
    <div className="title-actions">
      <img src={git} alt="" width="80.5" />
    </div>
    <div className="img-logo">
      <img src={logoChange} alt="" width="40.5" />
    </div>
  </div>
);
export default HeaderControl;
