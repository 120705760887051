import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEyeSlash, faList } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import AlertModal from './modal/AlertModal';
import ShowBlacklist from './modal/ShowBlacklist';
import { deleteTimesheet, updateHiddenField } from '../../callAPI';
import actions from '../../constants/actions';
import { useStateValue } from '../../state';
import '../../styles/layoutTimesheets/MainTimesheets/DrawTitleTimesheetAndButtons.css';
import WorkerInformation from './modal/WorkerInformation';


const DrawTitleTimesheetAndButtons = ({
  titleTimesheet, idDatabaseTimesheet, blacklist, error, showEditTimesheets,
}) => {
  const dispatch = useStateValue()[1];
  const [showDelete, setShowDelete] = useState(false);
  const [showHide, setShowHide] = useState(false);
  const [showBlacklist, setShowBlacklist] = useState(false);
  const [showError, setShowError] = useState(true);

  const [errorHide, setErrorHide] = useState(false);
  const [textErrorHide, setTextErrorHide] = useState('');
  const [errorDelete, setErrorDelete] = useState(false);
  const [textErrorDelete, setTextErrorDelete] = useState('');

  const showModalDelete = () => setShowDelete(true);
  const hideModalDelete = () => setShowDelete(false);
  const showModalHide = () => setShowHide(true);
  const hideModalHide = () => setShowHide(false);
  const showModalBlacklist = () => setShowBlacklist(true);
  const hideModalBlacklist = () => setShowBlacklist(false);

  const onSuccessDelete = async (id) => {
    const response = await deleteTimesheet(id);
    if (!response.error) {
      dispatch({ type: actions.DELETE_TIMESHEET, payload: id });
      hideModalDelete();
      setErrorDelete(false);
      setTextErrorDelete('');
    } else {
      setErrorDelete(true);
      setTextErrorDelete(response.payload);
    }
  };

  const onSuccessHide = async (id) => {
    const response = await updateHiddenField(id, true);
    if (!response.error) {
      dispatch({ type: actions.DELETE_TIMESHEET, payload: id });
      hideModalHide();
      setErrorHide(false);
      setTextErrorHide('');
    } else {
      setErrorHide(true);
      setTextErrorHide(response.payload);
    }
  };

  return (
    <>
      <div className="titleTimesheet-and-buttons">
        <div onClick={() => setShowError(true)} className={_.isEmpty(error) ? 'title-timesheet' : 'title-timesheet-red'}>
          <h6>{titleTimesheet}</h6>
        </div>
        <div className={showEditTimesheets ? 'timesheet-buttons' : 'timesheet-buttons-hide'}>
          <div className="deleteTimesheet-button">
            <FontAwesomeIcon icon={faTrash} size="xs" onClick={showModalDelete} />
          </div>
          <div className="hideTimesheet-button">
            <FontAwesomeIcon icon={faEyeSlash} size="xs" onClick={showModalHide} />
          </div>
          <div className="blacklist-button">
            <FontAwesomeIcon icon={faList} size="xs" onClick={showModalBlacklist} />
          </div>
        </div>
      </div>
      <div>
        { showError && !_.isEmpty(error)
          && (
          <WorkerInformation
            onClose={() => setShowError(false)}
            show={showError}
            header="Error"
            informationWorker={error}
            delay={8000}
          />
          )}
      </div>
      <AlertModal
        show={showDelete}
        onClose={hideModalDelete}
        onSuccess={() => onSuccessDelete(idDatabaseTimesheet)}
        head="Delete timesheet?"
        error={errorDelete}
        textError={textErrorDelete}
      />
      <AlertModal
        show={showHide}
        onClose={hideModalHide}
        onSuccess={() => onSuccessHide(idDatabaseTimesheet)}
        head="Hide timesheet?"
        error={errorHide}
        textError={textErrorHide}
      />
      <ShowBlacklist show={showBlacklist} onClose={hideModalBlacklist} blacklist={blacklist} idDatabaseTimesheet={idDatabaseTimesheet} />
    </>
  );
};
export default DrawTitleTimesheetAndButtons;
