import React, { useState } from 'react';
import '../../styles/layoutTimesheets/MenuButtons.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlusCircle, faEyeSlash, faHome, faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import SaveTimesheetDatabase from '../timesheets/modal/SaveTimesheetDatabase';
import TimesheetHidden from '../timesheets/modal/TimesheetHidden';


const MenuButtons = ({ switchShowState }) => {
  const [show, setShow] = useState(false);
  const [showHidden, setShowHidden] = useState(false);
  return (
    <div className="buttons">
      <div className="buttons-add-hide-edit">
        <div className="add-button">
          <FontAwesomeIcon icon={faPlusCircle} size="lg" onClick={() => setShow(true)} />
        </div>
        <div className="hide-button">
          <FontAwesomeIcon icon={faEyeSlash} size="lg" onClick={() => setShowHidden(true)} />
        </div>
        <div className="edit-button">
          <FontAwesomeIcon icon={faEdit} size="lg" onClick={switchShowState} />
        </div>
      </div>
      <div className="button-home">
        <Link to="/" style={{ color: 'black' }}>
          <FontAwesomeIcon className="home-button" icon={faHome} size="lg" />
        </Link>
      </div>
      <SaveTimesheetDatabase
        show={show}
        onClose={() => setShow(false)}
      />
      <TimesheetHidden
        show={showHidden}
        onClose={() => setShowHidden(false)}
      />
    </div>
  );
};

export default MenuButtons;
