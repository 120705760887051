/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Spinner, Alert } from "react-bootstrap";
import MessageBox from "./messageBox";
import { useStateValue } from "../../state";
import { getDailyFromSlack } from "../../callAPI";
import actions from "../../constants/actions";

const dailySlack = (date) => {
  const [loading, setLoading] = useState(false);
  const [{ dailyMessage }, dispatch] = useStateValue();

  useEffect(() => {
    const fetchCommits = async () => {
      const slackdaily = await getDailyFromSlack(date.date);

      setLoading(true);
      dispatch({
        type: actions.ADD_DAİLY_MESSAGE,
        payload: slackdaily.payload,
      });
    };

    fetchCommits();
  }, [dispatch]);
  console.log(dailyMessage);
  return loading === false ? (
    <div
      style={{
        margin: "auto",
        alignItems: "center",
        justifyContent: "center",
        height: "-webkit-fill-available",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Alert variant="info">
        We could not any message from {date.date} . Please make sure picked
        date!
      </Alert>
      <div>
        <Spinner animation="grow" variant="primary" />
        <Spinner animation="grow" variant="secondary" />
        <Spinner animation="grow" variant="success" />
        <Spinner animation="grow" variant="danger" />
        <Spinner animation="grow" variant="warning" />
        <Spinner animation="grow" variant="info" />
        <Spinner animation="grow" variant="light" />
        <Spinner animation="grow" variant="dark" />
      </div>
    </div>
  ) : (
    <>
      {dailyMessage === "" || dailyMessage === null ? (
        <Alert variant="info" dismissible>
          <Alert.Heading>
            We Could not find any message from slack!
          </Alert.Heading>
          <p>
            Please check internet connection then try again. Scraping to
            websites can need good internet connection. If you are downloading
            something or sharing your internet with others make sure your
            connection working well! and refresh the Page.
          </p>
        </Alert>
      ) : (
        <>
          {dailyMessage.allMessage.map((value) => (
            <MessageBox key={value.projectName} elem={value} />
          ))}
        </>
      )}
    </>
  );
};
export default dailySlack;
