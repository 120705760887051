import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import TimesheetsDatabase from '../TimesheetsDatabase';

const TimesheetHidden = ({
  show, onClose,
}) => (
  <Modal show={show} onHide={onClose} animation="true">
    <Modal.Header>
      <Modal.Title>Timesheets hidden</Modal.Title>
    </Modal.Header>
    <Modal.Body scrollable="true">
      <TimesheetsDatabase />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" size="sm" onClick={onClose}>
          Close
      </Button>
    </Modal.Footer>
  </Modal>
);
export default TimesheetHidden;
