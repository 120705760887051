
const getTimesheetFromUrl = (value) => {
  try {
    const ID_POSITION = 3;
    const url = new URL(value);
    const { pathname } = url;
    const timesheetId = pathname.split('/')[ID_POSITION];
    return { error: false, payload: timesheetId };
  } catch (error) {
    return ({ error: true, payload: error });
  }
};

export default getTimesheetFromUrl;
