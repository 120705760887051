/* eslint-disable */
import React from "react";

const MessageBox = (props) => {
  return (
    <div
      style={{
        textAlign: "center",
        width: "50% ",
        minWidth: "250px",
        margin: "auto",
        border: "1px black solid",
        marginBottom: "10px",
      }}
    >
      <h4>
        <b>Project : {props.elem.projectName}</b>
      </h4>
      <hr style={{ height: 2 }} />
      {props.elem.results !== null ? (
        props.elem.results.map((value) => (
          <div key={value}>
            <h5>
              <b>{value}</b>
            </h5>
            <br/>
          </div>
        ))
      ) : (
        <h4>There is no message in {props.elem.projectName} channel</h4>
      )}
    </div>
  );
};
export default MessageBox;
