import React from 'react';
import Routing from './components/Routing';
import { StateProvider } from './state';
import { reducer, initialState } from './reduce';

const App = () => (
  <StateProvider initialState={initialState} reducer={reducer}>
    <Routing />
  </StateProvider>
);

export default App;
