import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Alert } from 'react-bootstrap';
import { useStateValue } from '../../state';
import { getGithubData, getGitlabData } from '../../callAPI';
import actions from '../../constants/actions';
import GitCheckTable from '../git/GitCheckTable';


const Git = ({ date, showEditGit }) => {
  const [{
    gitlab, github,
  }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const [errorGit, setErrorGit] = useState(false);
  const [textError, setTextError] = useState('');

  useEffect(() => {
    const fetchCommits = async () => {
      if (gitlab.length || github.length) return;
      setLoading(true);
      const githubResponse = await getGithubData(date);
      const gitlabResponse = await getGitlabData(date);
      if (!githubResponse.error && !gitlabResponse.error) {
        dispatch({ type: actions.ADD_GITHUB_DATA, payload: githubResponse.payload });
        dispatch({ type: actions.ADD_GITLAB_DATA, payload: gitlabResponse.payload });
        setErrorGit(false);
      } else {
        setErrorGit(true);
        setTextError(gitlabResponse.payload);
      }
      setLoading(false);
    };
    fetchCommits();
  }, [gitlab.length, github.length, dispatch]);

  if (loading) {
    return (
      <Loader type="Rings" color="#31c6e8" height={150} width={100} />
    );
  }

  if (errorGit) {
    return (
      <Alert key="error" variant="danger">
        <Alert.Heading>Alert! Error download data from database</Alert.Heading>
        <p>
          {textError}
        </p>
        <hr />
      </Alert>
    );
  }

  return (
    <>
      <div> <h3> GitHub </h3></div>
      <div className="github">
        { github.map(({ project, users }) => (
          <GitCheckTable
            project={project}
            users={users}
            key={project._id}
            showEditGit={showEditGit}
          />
        ))}
      </div>
      <div> <h3> GitLab </h3></div>
      <div className="gitlab">
        { gitlab.map(({ project, users }) => (
          <GitCheckTable
            project={project}
            users={users}
            key={project._id}
            showEditGit={showEditGit}
          />
        ))}
      </div>
    </>
  );
};
export default Git;
