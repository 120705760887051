import React from 'react';
import '../../styles/layoutTimesheets/HeaderControl.css';
import logoTimesheets from '../../img/logoTimesheets.png';
import logoChange from '../../img/logo_change.png';

const HeaderControl = () => (
  <div className="header-control-container">
    <div className="title-actions">
      <img src={logoTimesheets} alt="" width="150.5" />
    </div>
    <div className="img-logo">
      <img src={logoChange} alt="" width="40.5" />
    </div>
  </div>
);
export default HeaderControl;
