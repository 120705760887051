import _ from 'lodash';
import actions from './constants/actions';
import DATE_OPCIONS from './config';

const initialState = {
  timesheets: {
    [DATE_OPCIONS.today]: [],
    [DATE_OPCIONS.yesterday]: [],
    [DATE_OPCIONS.threeDaysAgo]: [],
    [DATE_OPCIONS.twoDaysAgo]: [],
  },
  gitlab: [],
  github: [],
  databasetimesheets: [],
  dailyMessage: '',
};

const setterReducer = (prop) => (state, action) => ({
  ...state,
  [prop]: action.payload,
});

const deleteTimesheetHide = (state, action) => {
  const { databasetimesheets } = state;
  const id = action.payload;

  _.remove(databasetimesheets, (element) => element._id === id);
  return {
    ...state,
    databasetimesheets,
  };
};

const deleteNameBlacklist = (state, action) => {
  const { timesheets } = state;
  const { sheet, id } = action.payload;

  const update = _.mapKeys(timesheets, (value, key) => {
    const index = _.findIndex(value, (timesheet) => timesheet._id === id);
    if (index !== -1) {
      const { blacklist } = value[index];
      _.remove(blacklist, (element) => element === sheet);
    }
    return key;
  });

  return {
    ...state,
    timesheets: update,
  };
};

const deleteSheet = (state, action) => {
  const { timesheets } = state;
  const { sheet, id } = action.payload;

  const update = _.mapKeys(timesheets, (value, key) => {
    const index = _.findIndex(value, (timesheet) => timesheet._id === id);
    if (index !== -1) {
      const { informationSheets } = value[index];
      _.remove(informationSheets, (element) => element.nameSheet === sheet);
    }
    return key;
  });

  return {
    ...state,
    timesheets: update,
  };
};

const deleteTimesheet = (state, action) => {
  const { timesheets } = state;
  const id = action.payload;

  const update = _.mapKeys(timesheets, (value, key) => {
    const current = value;
    _.remove(current, (element) => element._id === id);
    return key;
  });

  return {
    ...state,
    timesheets: update,
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.ADD_GITHUB_DATA: return setterReducer('github')(state, action);
    case actions.ADD_GITLAB_DATA: return setterReducer('gitlab')(state, action);
    case actions.ADD_TIMESHEETS:
      const { timesheets } = state;
      const { dateOption, dataTimesheets } = action.payload;
      const updateTimesheets = { ...timesheets, [dateOption]: dataTimesheets };
      return {
        ...state,
        timesheets: updateTimesheets,
      };
    case actions.DELETE_TIMESHEET:
      return deleteTimesheet(state, action);
    case actions.DELETE_SHEET:
      return deleteSheet(state, action);
    case actions.DELETE_BLACKLIST_NAME:
      return deleteNameBlacklist(state, action);
    case actions.DELETE_TIMESHEET_HIDE:
      return deleteTimesheetHide(state, action);
    case actions.ADD_DATABASE_TIMESHEETS:
      return setterReducer('databasetimesheets')(state, action);
    case actions.ADD_DAİLY_MESSAGE:
      return setterReducer('dailyMessage')(state, action);
    default:
      return state;
  }
};

export { reducer, initialState };
