import React from 'react';
import {
  Button, Modal, Alert,
} from 'react-bootstrap';

const AlertModal = ({
  show, onClose, onSuccess, head, error, textError,
}) => (
  <Modal show={show} onHide={onClose} animation="true">
    <Modal.Header closeButton>
      <Modal.Title>{head}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {error
        ? (
          <Alert key="error" variant="danger">
            <Alert.Heading>Alert! Error </Alert.Heading>
            <p>
              {textError}
            </p>
            <hr />
          </Alert>
        )
        : <p> </p>}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" size="sm" onClick={onClose}>
        Close
      </Button>
      <Button variant="info" size="sm" onClick={onSuccess}>
        Save Changes
      </Button>
    </Modal.Footer>
  </Modal>
);
export default AlertModal;
