import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { updateHiddenField } from '../../callAPI';
import actions from '../../constants/actions';
import { useStateValue } from '../../state';
import '../../styles/layoutTimesheets/MainTimesheets/CardDatabase.css';


const CardDatabase = ({
  _id, title,
}) => {
  const dispatch = useStateValue()[1];

  const onSuccess = async (id) => {
    const response = await updateHiddenField(id, false);
    if (!response.error) {
      dispatch({ type: actions.DELETE_TIMESHEET_HIDE, payload: id });
    }
  };

  return (
    <>
      <div className="titleTimesheetHidden-iconShow">
        <div className="titleTimesheetHidden">
          {title}
        </div>
        <div className="iconShow">
          <FontAwesomeIcon icon={faEyeSlash} size="xs" onClick={() => onSuccess(_id)} />
        </div>
      </div>
    </>
  );
};
export default CardDatabase;
