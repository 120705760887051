import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import RowOfName from '../RowOfName';

const ShowBlacklist = ({
  show, onClose, blacklist, idDatabaseTimesheet,
}) => {
  const rowOfWorkers = blacklist.map((name) => (
    <RowOfName idDatabaseTimesheet={idDatabaseTimesheet} name={name} />
  ));
  return (
    <Modal show={show} onHide={onClose} animation="true">
      <Modal.Header>
        <Modal.Title>Blacklist</Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable="true">
        {rowOfWorkers}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" size="sm" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default ShowBlacklist;
