import React, { useState } from 'react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { Toggle } from 'office-ui-fabric-react/lib';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Button, Modal } from 'react-bootstrap';
import { postTimesheet, getValidateTimesheetId } from '../../../callAPI';
import getTimesheetFromUrl from '../../../utils/helpers';


const SaveTimesheetDatabase = ({ show, onClose }) => {
  const [title, setTitle] = useState('');
  const [url, setUrl] = useState('');
  const blacklist = [];
  const [isHidden, setIsHidden] = useState(false);
  const [isErrorText, setIsErrorText] = useState(false);
  const [isErrorTextPost, setIsErrorTextPost] = useState(false);
  const [textPost, setTextPost] = useState('');
  const [errorText, setErrorText] = useState('');

  const handleTitle = (event) => {
    setTitle(event.target.value);
    setIsErrorText(false);
    setTextPost('');
  };
  const handleUrl = (event) => {
    setUrl(event.target.value);
    setIsErrorText(false);
    setTextPost('');
  };
  const Hidden = () => (
    isHidden ? setIsHidden(false) : setIsHidden(true)
  );

  const onSuccessPost = async () => {
    const timesheetId = getTimesheetFromUrl(url);
    const validate = await getValidateTimesheetId(timesheetId.payload);
    if ((title !== '') && (!timesheetId.error) && (!validate.error)) {
      setIsErrorText(false);
      const responsePost = await postTimesheet(title, blacklist, timesheetId.payload, isHidden);
      if (responsePost.error) {
        setTextPost(responsePost.payload);
        setIsErrorTextPost(true);
      } else {
        setIsErrorTextPost(false);
        setTextPost('Successfully uploaded');
      }
    } else {
      setIsErrorText(true);
      if (title === '') {
        setErrorText('Error: Unfilled field');
      } else if (timesheetId.error) {
        setErrorText(`Error: ${timesheetId.payload}`);
      } else {
        setErrorText(`Error: ${validate.payload}`);
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} animation="true">
      <Modal.Header closeButton>
        <Modal.Title>Add timesheet to the database</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { width: 650 } }}>
          <Stack>
            <TextField label="title" autoAdjustHeight="true" required value={title} onChange={handleTitle} borderless={false} />
            <TextField label="URL timesheet " required value={url} onChange={handleUrl} />
            <Toggle label="Hide timesheet?" onText="yes" offText="no" checked={isHidden} onChange={Hidden} />
            {isErrorText
              ? <Text block variant="smallPlus" style={{ color: 'red' }}>{errorText}</Text>
              : <Text block> </Text>}
            {isErrorTextPost
              ? <Text block variant="smallPlus" style={{ color: 'red' }}>{textPost}</Text>
              : <Text block variant="smallPlus" style={{ color: '#31e89f' }}>{textPost}</Text>}
          </Stack>
        </Stack>
        <Modal.Footer>
          <Button variant="primary" size="sm" onClick={onClose}>
          Close
          </Button>
          <Button variant="info" size="sm" onClick={onSuccessPost}>
          Save Changes
          </Button>
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
};
export default SaveTimesheetDatabase;
