import React, { useState } from 'react';
import '../../styles/layoutCalendar/LayoutCalendar.css';
import { Link } from 'react-router-dom';
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import Footer from '../layoutHome/Footer';
import Header from '../layoutHome/Header';
import MenuButton from '../layoutDays/MenuButton';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';


const LayoutDays = ({ from }) => {
  const [date, setDate] = useState(moment());
  const [focused, setFocused] = useState(true);
  const onDateChange = (dat) => {
    setDate(dat);
  };
  const onFocusedChange = (focuse) => setFocused(focuse.focused);

  const isDayOutside = (day) => day >= moment();

  return (
    <div className="body-calendar">
      <div className="header-calendar"> <Header title="StriveLabs" /> </div>
      <div className="menu-calendar"> <MenuButton /> </div>
      <div className="main-calendar">
        <div className="text">
          <h4 className="textH4">Choose a date </h4>
        </div>
        <div className="calendar-button">
          <SingleDatePicker
            date={date}
            focused={focused}
            horizontalMargin={12}
            numberOfMonths={1}
            isOutsideRange={isDayOutside}
            onFocusChange={onFocusedChange}
            onDateChange={onDateChange}
            regular
            block
          />
          <Link to={from === 'slack' ? `/daily?date=${date.format('YYYY-MM-DD')}` : `/git?date=${date.format('YYYY-MM-DD')}`} style={{ color: 'black' }}>
            <Button className="button" variant="success" size="sm">Go</Button>
          </Link>
        </div>
      </div>
      <div className="footer-calendar"> <Footer />  </div>
    </div>
  );
};
export default LayoutDays;
