import React, { useState } from 'react';
import '../../styles/layoutTimesheets/LayoutTimesheets.css';
import Footer from '../layoutHome/Footer';
import HeaderControl from './HeaderControl';
import MenuButtons from './MenuButtons';
import Timesheets from './Timesheets';


const LayoutTimesheets = ({ dateOption }) => {
  const [showEditTimesheets, setShowEditTimesheets] = useState(false);

  const switchShowState = () => {
    setShowEditTimesheets(!showEditTimesheets);
  };

  return (
    <div className="body-timesheets">
      <div className="header-timesheets">
        <HeaderControl title="Timesheets" />
      </div>
      <div className="menu-timesheets">
        <MenuButtons switchShowState={switchShowState} />
      </div>
      <div className="main-timesheets">
        <Timesheets dateOption={dateOption} showEditTimesheets={showEditTimesheets} />
      </div>
      <div className="footer-timesheets"> <Footer />  </div>
    </div>

  );
};

export default LayoutTimesheets;
