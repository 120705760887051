import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faList } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import AlertModal from '../timesheets/modal/AlertModal';
import '../../styles/git/RowTitleGitProject.css';


const RowTitleGitProject = ({ project, showEditGit }) => {
  const {
    projectId, name, _id, gitApi, isHidden,
  } = project;
  const [showHide, setShowHide] = useState(false);

  const hideModalHide = () => setShowHide(false);


  return (
    <>
      <div className="titleProject-and-buttons">
        <div className="title-project">
          <h6>{name}</h6>
        </div>
        <div className={showEditGit ? 'project-buttons' : 'project-buttons-hide'}>
          <div className="hideProject-button">
            <FontAwesomeIcon icon={faEyeSlash} size="xs" />
          </div>
          <div className="blacklistGit-button">
            <FontAwesomeIcon icon={faList} size="xs" />
          </div>
        </div>
      </div>
      <AlertModal
        show={showHide}
        onClose={hideModalHide}
        head="Hide timesheet?"
      />
    </>
  );
};
export default RowTitleGitProject;
