import React from 'react';
import '../../styles/layoutHome/Header.css';
import striveLabsControl from '../../img/striveLabsControl.png';
import logoChange from '../../img/logo_change.png';

const Header = () => (
  <div className="header-container">
    <div className="title">
      <img src={striveLabsControl} alt="" width="110" />
    </div>
    <div className="img-logo">
      <img src={logoChange} alt="" width="42.5" />
    </div>
  </div>
);
export default Header;
