
const apiUrl = process.env.REACT_APP_API_URL;
const timesheetsyesterday = '/api/v1/checkTimesheets/?indexDay=1';
const timesheetsToday = '/api/v1/checkTimesheets/?indexDay=0';
const timesheetsTwoDaysAgo = '/api/v1/checkTimesheets/?indexDay=2';
const timesheetsThreeDaysAgo = '/api/v1/checkTimesheets/?indexDay=3';
const getTimesheetsDatabase = '/api/v1//timesheetDatabase/timesheets/?isHidden=';
const deleteTimesheetDatabase = '/api/v1/timesheetDatabase/';
const updateHiddenField = '/api/v1//timesheetDatabase/hidden/';
const addBlacklistField = '/api/v1/timesheetDatabase/addBlacklist/';
const deleteBlacklistField = '/api/v1/timesheetDatabase/deleteBlacklist/';
const postTimesheet = '/api/v1//timesheetDatabase';
const getValidateTimesheet = '/api/v1/validateTimesheetId/?timesheetId=';
const getCheckTimesheet = '/api/v1/checkTimesheet/?indexDay=0';
const gitlabData = '/api/v1/gitlabData?date=';
const githubData = '/api/v1/githubData?date=';
const slackDaily = '/api/v1/daily';

const urlGetTimesheetsDatabase = `${apiUrl}${getTimesheetsDatabase}`;
const urlTimeSheetsYesterday = `${apiUrl}${timesheetsyesterday}`;
const urlTimesheetsThreeDaysAgo = `${apiUrl}${timesheetsThreeDaysAgo}`;
const urlTimesheetsTwoDaysAgo = `${apiUrl}${timesheetsTwoDaysAgo}`;
const urlTimeSheetsToday = `${apiUrl}${timesheetsToday}`;
const urldeleteTimesheetDatabase = `${apiUrl}${deleteTimesheetDatabase}`;
const urlUpdateHiddenField = `${apiUrl}${updateHiddenField}`;
const urlAddBlacklistField = `${apiUrl}${addBlacklistField}`;
const urldeleteBlacklistField = `${apiUrl}${deleteBlacklistField}`;
const urlPostTimesheet = `${apiUrl}${postTimesheet}`;
const urlGetValidateTimesheet = `${apiUrl}${getValidateTimesheet}`;
const urlGetCheckTimesheet = `${apiUrl}${getCheckTimesheet}`;
const urlGitlabData = `${apiUrl}${gitlabData}`;
const urlGithubData = `${apiUrl}${githubData}`;
const urlSlackDaily = `${apiUrl}${slackDaily}`;
export {
  urlTimeSheetsYesterday, urlTimeSheetsToday, urlGetTimesheetsDatabase, urlTimesheetsThreeDaysAgo,
  urlTimesheetsTwoDaysAgo, urldeleteTimesheetDatabase, urlUpdateHiddenField,
  urlAddBlacklistField, urlPostTimesheet, urldeleteBlacklistField, urlGetValidateTimesheet, urlSlackDaily,
  urlGetCheckTimesheet, urlGitlabData, urlGithubData,
};
