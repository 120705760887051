import React from 'react';
import '../../styles/layoutHome/Box.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Box = ({ nameIcon, name }) => (
  <div className="box-container">
    <div className="icon-text">
      <FontAwesomeIcon className="icon" icon={nameIcon} size="lg" />
      <h4 className="name"> {name} </h4>
    </div>
  </div>
);
export default Box;
