import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { Alert } from 'react-bootstrap';
import { getTimeSheets } from '../../callAPI';
import { useStateValue } from '../../state';
import actions from '../../constants/actions';
import TimesheetCheckTable from '../timesheets/TimesheetCheckTable';


const Timesheets = ({ dateOption, showEditTimesheets }) => {
  const [{
    timesheets,
  }, dispatch] = useStateValue();
  const myDateOptionTimesheets = timesheets[dateOption];
  const [loading, setLoading] = useState(false);
  const [errorTimesheets, setErrorTimesheets] = useState(false);
  const [textError, setTextError] = useState('');

  useEffect(() => {
    const fetchTimeSheets = async () => {
      if (myDateOptionTimesheets.length) return;
      setLoading(true);
      const newTimeSheets = await getTimeSheets(dateOption);
      if (!newTimeSheets.error) {
        dispatch({ type: actions.ADD_TIMESHEETS, payload: { dateOption, dataTimesheets: newTimeSheets.payload } });
        setErrorTimesheets(false);
      } else {
        setErrorTimesheets(true);
        setTextError(newTimeSheets.payload);
      }
      setLoading(false);
    };
    fetchTimeSheets();
  }, [myDateOptionTimesheets.length, dispatch]);

  if (loading) {
    return (
      <div className="timesheets2">
        <Loader type="Puff" color="#31c6e8" height={350} width={150} />
      </div>
    );
  }

  if (errorTimesheets) {
    return (
      <div className="timesheets2">
        <Alert key="error" variant="danger">
          <Alert.Heading>Alert! Error download timesheets</Alert.Heading>
          <p>
            {textError}
          </p>
          <hr />
        </Alert>
      </div>
    );
  }

  return (
    <div className="timesheets2">
      { myDateOptionTimesheets.map(({
        _id, titleTimesheet, blacklist, error, informationSheets,
      }) => (
        <TimesheetCheckTable
          idDatabaseTimesheet={_id}
          titleTimesheet={titleTimesheet}
          blacklist={blacklist}
          error={error}
          sheets={informationSheets}
          showEditTimesheets={showEditTimesheets}
          key={_id}
        />
      ))}
    </div>
  );
};
export default Timesheets;
