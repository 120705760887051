import React from 'react';
import DrawTitleTimesheetAndButtons from './DrawTitleTimesheetAndButtons';
import RowOfWorkers from './RowOfWorkers';
import '../../styles/layoutTimesheets/MainTimesheets/TimesheetCheckTable.css';


const TimesheetCheckTable = ({
  sheets, titleTimesheet, blacklist, error, idDatabaseTimesheet, showEditTimesheets,
}) => {
  const rowOfWorkers = sheets.map(({
    nameSheet, isCompleted, totalHours, dataRelatedToGeneralTimesheet, description,
  }) => (
    <RowOfWorkers
      idDatabaseTimesheet={idDatabaseTimesheet}
      nameSheet={nameSheet}
      isCompleted={isCompleted}
      dataRelatedToGeneralTimesheet={dataRelatedToGeneralTimesheet}
      totalHours={totalHours}
      showEditTimesheets={showEditTimesheets}
      description={description}
      key={nameSheet}
    />
  ));
  return (
    <>
      <div className="timesheet-Workers">
        <DrawTitleTimesheetAndButtons
          titleTimesheet={titleTimesheet}
          blacklist={blacklist}
          error={error}
          idDatabaseTimesheet={idDatabaseTimesheet}
          showEditTimesheets={showEditTimesheets}
          key={titleTimesheet}
        />
        {rowOfWorkers}
      </div>
    </>
  );
};

export default TimesheetCheckTable;
