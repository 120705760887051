import axios from 'axios';
import {
  urlTimeSheetsToday, urlTimeSheetsYesterday, urlGetTimesheetsDatabase, urlTimesheetsThreeDaysAgo,
  urlTimesheetsTwoDaysAgo, urldeleteTimesheetDatabase, urlUpdateHiddenField, urlAddBlacklistField,
  urlPostTimesheet, urldeleteBlacklistField, urlGetValidateTimesheet, urlGetCheckTimesheet, urlGithubData, urlGitlabData, urlSlackDaily,
} from './configuration/urlLocalAccess';
import DATE_OPCIONS from './config';


const getData = (response) => response.data.objects;

const getGithubData = async (date) => {
  try {
    const response = await axios.get(`${urlGithubData}${date}`);
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const getGitlabData = async (date) => {
  try {
    const response = await axios.get(`${urlGitlabData}${date}`);
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};


const getTimesheet = async (timesheet) => {
  try {
    const response = await axios.post(urlGetCheckTimesheet, timesheet);
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const getTimeSheets = async (dateOpcions) => {
  if (dateOpcions === DATE_OPCIONS.today) {
    try {
      const response = await axios.get(urlTimeSheetsToday);
      const data = getData(response);
      return { error: false, payload: data };
    } catch (error) {
      return { error: true, payload: error.message };
    }
  }
  if (dateOpcions === DATE_OPCIONS.yesterday) {
    try {
      const response = await axios.get(urlTimeSheetsYesterday);
      const data = getData(response);
      return { error: false, payload: data };
    } catch (error) {
      return { error: true, payload: error.message };
    }
  }
  if (dateOpcions === DATE_OPCIONS.threeDaysAgo) {
    try {
      const response = await axios.get(urlTimesheetsThreeDaysAgo);
      const data = getData(response);
      return { error: false, payload: data };
    } catch (error) {
      return { error: true, payload: error.message };
    }
  }
  try {
    const response = await axios.get(urlTimesheetsTwoDaysAgo);
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const getDatabaseTimesheets = async (isHidden) => {
  try {
    const response = await axios.get(`${urlGetTimesheetsDatabase}${isHidden}`);
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const deleteTimesheet = async (databaseId) => {
  try {
    const response = await axios.delete(`${urldeleteTimesheetDatabase}${databaseId}`);
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const updateHiddenField = async (databaseId, isHidden) => {
  try {
    const response = await axios.put(`${urlUpdateHiddenField}${databaseId}`, { isHidden });
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const addBlacklistField = async (databaseId, sheet) => {
  try {
    const response = await axios.put(`${urlAddBlacklistField}${databaseId}`, { sheet });
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const deleteBlacklistField = async (databaseId, sheet) => {
  try {
    const response = await axios.put(`${urldeleteBlacklistField}${databaseId}`, { sheet });
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

const postTimesheet = async (title, blacklist, timesheetId, isHidden) => {
  try {
    const response = await axios.post(urlPostTimesheet, {
      title, blacklist, timesheetId, isHidden,
    });
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.response.data.message };
  }
};

const getValidateTimesheetId = async (timesheetId) => {
  try {
    const response = await axios.get(`${urlGetValidateTimesheet}${timesheetId}`);
    return { error: false, payload: response };
  } catch (error) {
    return { error: true, payload: error.response.data.message };
  }
};
const getDailyFromSlack = async (date) => {
  try {
    const response = await axios.post(urlSlackDaily, { date });
    const data = getData(response);
    return { error: false, payload: data };
  } catch (error) {
    return { error: true, payload: error.message };
  }
};

export {
  getTimeSheets, getDatabaseTimesheets, deleteTimesheet, getGithubData, getGitlabData, getDailyFromSlack,
  updateHiddenField, addBlacklistField, postTimesheet, deleteBlacklistField, getValidateTimesheetId, getTimesheet,
};
