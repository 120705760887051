import React from 'react';
import RowTitleGitProject from './RowTitleGitProject';
import RowGitWorkers from './RowGitWorkers';
import '../../styles/git/GitCheckTable.css';


const GitCheckTable = ({ project, users, showEditGit }) => {
  const rowOfWorkers = users.map(({
    isHidden, gitApi, userId, name, _id, counter,
  }) => (
    <RowGitWorkers
      isHidden={isHidden}
      gitApi={gitApi}
      userId={userId}
      name={name}
      _id={_id}
      counter={counter}
      key={_id}
      showEditGit={showEditGit}
    />
  ));
  return (
    <>
      <div className="git-Workers">
        <RowTitleGitProject
          project={project}
          key={project._id}
          showEditGit={showEditGit}
        />
        {rowOfWorkers}
      </div>
    </>
  );
};

export default GitCheckTable;
