import React from 'react';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteBlacklistField } from '../../callAPI';
import '../../styles/layoutTimesheets/MainTimesheets/RowOfName.css';
import { useStateValue } from '../../state';
import actions from '../../constants/actions';

const RowOfName = ({
  name, idDatabaseTimesheet,
}) => {
  const dispatch = useStateValue()[1];


  const onSuccess = async (id, sheet) => {
    const response = await deleteBlacklistField(id, sheet);
    if (!response.error) {
      dispatch({ type: actions.DELETE_BLACKLIST_NAME, payload: { sheet, id } });
    }
  };
  return (
    <>
      <div className="nameBlacklist-deleteButton">
        <div className="nameBlacklist">
          {name}
        </div>
        <div className="deleteButton">
          <FontAwesomeIcon icon={faTrashAlt} size="xs" onClick={() => onSuccess(idDatabaseTimesheet, name)} />
        </div>
      </div>
    </>
  );
};

export default RowOfName;
