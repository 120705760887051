import React from 'react';
import '../../styles/layoutDays/BoxDays.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const BoxDays = ({ date, day }) => (
  <div className="box-day-container">
    <div className="date-day">
      <h5 className="date"> {date} </h5>
      <FontAwesomeIcon className="arrow" icon={faArrowRight} size="lg" />
      <h5 className="day"> {day} </h5>
    </div>
  </div>
);
export default BoxDays;
