import React from 'react';
import '../../styles/layoutHome/LayoutHome.css';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { faSlack, faGitSquare } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import Box from './Box';

const LayoutHome = () => (
  <div className="body">
    <div className="header">  <Header title="StriveLabs" /> </div>
    <div className="main">
      <Link to="/days" style={{ color: 'black' }}>
        <Box nameIcon={faCalendarAlt} name="Timesheets" />
      </Link>
      <Link to="/calendar/git" style={{ color: 'black' }}>
        <Box nameIcon={faGitSquare} name="Commits" />
      </Link>
      <Link to="/calendar/slack" style={{ color: 'black' }}>
        <Box nameIcon={faSlack} name="Slack" />
      </Link>
    </div>
    <div className="footer"> <Footer />  </div>
  </div>
);
export default LayoutHome;
