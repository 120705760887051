import React from 'react';
import { Toast } from 'react-bootstrap';

const WorkerInformation = ({
  onClose, show, header, informationWorker, delay,
}) => (
  <Toast onClose={onClose} show={show} delay={delay} autohide>
    <Toast.Header>
      <strong className="mr-auto">{header}</strong>
    </Toast.Header>
    <Toast.Body>{informationWorker}</Toast.Body>
  </Toast>

);
export default WorkerInformation;
